import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { translate } from 'react-polyglot';
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import styled from 'styled-components';

import AppBar from './AppBar';
import Footer from './Footer';
import BaseHeaderImage from './HeaderImage';
import MaxWidthContainer from './MaxWidthContainer';
import TopBar from './TopBar';

const DEV = 'dev';
const FACILITATOR = 'facilitator';
const CEO = 'ceo';
const COO = 'coo';
const SALE = 'sale';
const MANYTHINGS = 'manyThings';

const Container = styled.div`
    background-color: ${({ theme }) => theme.gray}
    display: flex;
    flex-direction: column;
`;

const HeaderImage = styled(BaseHeaderImage)`
    > div:first-child {
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 100%
        );
    }
    @media print {
        display: none;
    }
`;

const Intro = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8rem 0;

    @media (max-width: 600px) {
        margin: 4rem 0;
    }
`;
const Title = styled.h1`
    color: ${({ theme }) => theme.white};
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 3rem;
    text-align: center;

    @media (max-width: 600px) {
        font-size: 1.75rem;
        line-height: 2rem;
        margin: 0 3rem;
    }

    @media (max-width: 320px) {
        font-size: 1.75rem;
        line-height: 2rem;
        margin: 0 2rem;
    }
`;

const ArrowUp = styled.div`
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid ${({ theme }) => theme.white};
    position: absolute;
    bottom: 0;
    left: 50%;
`;

const GlobalContainer = styled.div`
    background-color: ${({ theme }) => theme.gray};
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 20px;
`;

const MemberImage = styled.img`
    border-radius: 50%;

    @media (max-width: 600px) {
        height: 4rem;
        width: 4rem;
    }
`;

const Scene = styled.div`
    vertical-align: bottom;

    height: ${({ size }) => size} !important;
    width: ${({ size }) => size} !important;

    @media (max-width: 600px) {
        height: 2rem;
        width: 115px;
    }
`;

const ImageContainer = styled.div`
    height: 100%;
    width: 100%;

    > img {
        width: 100%;
        height: 100%;
        color: white;
        text-align: center;
    }
`;

const MemberContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    border: 1px solid black;
    padding: 10px;
    margin: ${({ root }) => (root ? 'auto' : '0 10px 10px 10px')};
    width: 280px;
    @media print {
        zoom: 50%;
        font-size: 14px;
    }
`;

const MemberTextContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    strong,
    span {
        text-transform: capitalize;
    }
    a {
        @media print {
            display: none;
        }
        color: inherit;
    }
`;

const Name = styled.strong`
    font-weight: bold;
    @media print {
        font-weight: 800;
    }
`;

const Role = styled.span`
    @media print {
        font-weight: 600;
    }
`;

const Member = ({ root, member, imageSize, t }) => {
    return (
        <MemberContainer root={root}>
            <Scene size={imageSize}>
                <ImageContainer>
                    <MemberImage
                        role="presentation"
                        className="default"
                        src={member.picture}
                    />
                </ImageContainer>
            </Scene>
            <MemberTextContainer>
                <Name>{member.fullname}</Name>
                <Role>{t(`organization.${member.role}`)}</Role>
                <a target="_blank" href={member.link} rel="noreferrer">
                    {t('organization.link')}
                </a>
            </MemberTextContainer>
        </MemberContainer>
    );
};

Member.propTypes = {
    root: PropTypes.boolean,
    member: PropTypes.object.isRequired,
    imageSize: PropTypes.string,
    t: PropTypes.func.isRequired,
};

Member.defaultProps = {
    imageSize: '6rem',
};

const MembersContainer = ({ members, t }) => {
    return (
        <div>
            {members.map(member => (
                <Member key={member.name} member={member} t={t} />
            ))}
        </div>
    );
};

MembersContainer.propTypes = {
    members: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
};

const TeamTree = ({ team, t }) => {
    const root = team.find(member => member.role === CEO);
    const coo = team.find(member => member.role === COO);
    const devs = team.filter(member => member.role === DEV && !member.ra);
    const devsRA = team.filter(member => member.role === DEV && member.ra);
    const sales = team.filter(member => member.role === SALE);
    const manyThings = team.filter(member => member.role === MANYTHINGS);
    const facilitators = team.filter(member => member.role === FACILITATOR);
    return (
        <TeamNode node={<Member member={root} t={t} root />}>
            <TeamNode node={<Member member={coo} t={t} root />}>
                <MembersContainer members={devs} t={t} />
                <MembersContainer members={facilitators} t={t} />
            </TeamNode>
            <MembersContainer members={devsRA} t={t} />
            <MembersContainer members={sales} t={t} />
            <MembersContainer members={manyThings} t={t} />
        </TeamNode>
    );
};

TeamTree.propTypes = {
    team: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
};

const NodeContainer = styled.div`
    --tree-line-height: 20px;
    --tree-line-width: 1px;
    --tree-line-border-radius: 10px;
    --tree-node-padding: 20px;
    --tree-line-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: center;
`;

const ChildrenContainer = styled.ul`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    align-items: flex-start;
    padding-inline-start: 0;
    margin: 0;
    position: relative;
    padding-top: var(--tree-line-height);
    ::before {
        content: '';
        position: absolute;
        top: 0;
        height: var(--tree-line-height);
        box-sizing: border-box;
        left: 50%;
        width: 0;
        border-left: var(--tree-line-width) solid var(--tree-line-color);
    }
`;

const ChildContainer = styled.li`
    flex: auto;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: var(--tree-line-height) var(--tree-node-padding) 0
        var(--tree-node-padding);
    ::before,
    ::after {
        content: '';
        position: absolute;
        top: 0;
        height: var(--tree-line-height);
        box-sizing: border-box;
        right: 50%;
        width: 50%;
        border-top: var(--tree-line-width) solid var(--tree-line-color);
    }
    :first-of-type::before {
        border: 0 none;
    }
    :last-of-type::before {
        border-right: var(--tree-line-width) solid var(--tree-line-color);
        border-radius: 0 var(--tree-line-border-radius) 0 0;
    }
    ::after {
        left: 50%;
        border-left: var(--tree-line-width) solid var(--tree-line-color);
    }
    :first-of-type::after {
        border-radius: var(--tree-line-border-radius) 0 0 0;
    }
    :last-of-type::after {
        border: 0 none;
    }
`;

const TeamNode = ({ node, children }) => {
    return (
        <NodeContainer>
            {node}
            {children.length > 0 && (
                <ChildrenContainer>
                    {children.map((child, index) => (
                        <ChildContainer key={index}>{child}</ChildContainer>
                    ))}
                </ChildrenContainer>
            )}
        </NodeContainer>
    );
};

TeamNode.propTypes = {
    node: PropTypes.node,
    children: PropTypes.arrayOf(PropTypes.node),
};

const Organization = ({ locale, team, headerImage, t }) => {
    return (
        <Container>
            <Helmet title={t('seo.organizationTitle')}>
                <html lang={locale} />
                <meta
                    name="description"
                    content={t('seo.organizationDescription')}
                />
            </Helmet>
            <TopBar white role="presentation" />
            <header>
                <AppBar />
                <HeaderImage image={headerImage}>
                    <MaxWidthContainer>
                        <Intro>
                            <Title>
                                {t('organization.preTitle')}
                                <br />
                                {t('organization.title')}
                            </Title>
                        </Intro>
                    </MaxWidthContainer>
                    <div>
                        <ArrowUp />
                    </div>
                </HeaderImage>
            </header>
            <div role="main">
                <MaxWidthContainer>
                    <GlobalContainer>
                        <TeamTree team={team} t={t} />
                    </GlobalContainer>
                </MaxWidthContainer>
            </div>
            <Footer />
        </Container>
    );
};

Organization.propTypes = {
    locale: PropTypes.string,
    team: PropTypes.array.isRequired,
    headerImage: PropTypes.object,
    t: PropTypes.func.isRequired,
};

export default compose(
    mapProps(({ data, locale }) => ({
        locale,
        team: data.team.edges
            .filter(({ node: { frontmatter } }) => !frontmatter.retired)
            .map(({ node: { frontmatter, parent } }) => ({
                ...frontmatter,
                filename: parent.name,
            }))
            .sort((a, b) => (a.filename < b.filename ? -1 : 1)),
        headerImage: data.headerImage,
    })),
    translate(),
)(Organization);
